import { createPlugin, createRoutableExtension, createRouteRef } from '@backstage/core-plugin-api';


export const rootRouteRef = createRouteRef({
  id: 'profile-settings',
});

export const profileSettingsPlugin = createPlugin({
  id: 'profile-settings',
  routes: {
    root: rootRouteRef,
  },
});

export const ProfileSettingsPage = profileSettingsPlugin.provide(
  createRoutableExtension({
    name: 'ProfileSettingsPage',
    component: () =>
      import('./components/SettingsPage').then(m => m.SettingsPage),
    mountPoint: rootRouteRef,
  }),
);
