import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  createUnifiedTheme,
} from '@backstage/theme';
import banner_bg_image from '../banner/Main_Header_banner.svg';
const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: '#0a030d',
  };
});
export const themeColors = {
  "primary": "#602080",
  "primary-neutral": "#0a030d",
  "secondary": "#8EE7F5",
  "sideBar-bg": "#131928",
  "content-area-bg": "#050d21",
  "content-area-90": "#252B3B",
  "item-card-bg": "#393F51",
  "secondary-neutral": "#0e1718",
  "primary-90": "#efe9f2",
  "primary-100": "#dfd2e6",
  "primary-200": "#bfa6cc",
  "primary-300": "#a079b3",
  "primary-400": "#804d99",
  "primary-600": "#4d1a66",
  "primary-700": "#3a134d",
  "primary-800": "#260d33",
  "primary-900": "#13061a",
  "secondary-90": "#f4fdfe",
  "secondary-100": "#e8fafd",
  "secondary-200": "#d2f5fb",
  "secondary-300": "#bbf1f9",
  "secondary-400": "#a5ecf7",
  "secondary-600": "#72b9c4",
  "secondary-700": "#558b93",
  "secondary-800": "#395c62",
  "secondary-900": "#1c2e31",
  "primary-neutral-90": "#e7e6e7",
  "primary-neutral-80": "#cecdcf",
  "primary-neutral-100": "#9d9a9e",
  "primary-neutral-200": "#6c686e",
  "primary-neutral-300": "#3b353d",
  "primary-neutral-400": "#231c25",
  "secondary-neutral-90": "#e7e8e8",
  "secondary-neutral-80": "#cfd1d1",
  "secondary-neutral-100": "#9fa2a3",
  "secondary-neutral-200": "#6e7474",
  "secondary-neutral-300": "#3e4546",
  "secondary-neutral-400": "#262e2f",
}
export const ideaToLifeTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: themeColors.primary,
        // light: '#efe9f2',
        dark: themeColors.primary,
        contrastText: themeColors["primary-neutral-90"]
      },
      secondary: {
        main: themeColors.secondary,
        // light: '#FFAB00',
        dark: themeColors.secondary,
        contrastText: themeColors["primary-neutral-90"]
      },
      grey: {
        50: '#C1C7D0',
        100: '#7A869A',
        200: '#6B778C',
        300: '#5E6C84',
        400: '#505F79',
        500: '#42526E',
        600: '#344563',
        700: '#253858',
        800: themeColors["primary-neutral"],
        900: '#091E42',
        1000: themeColors["sideBar-bg"],
        1100: themeColors["content-area-90"],
        1200: themeColors["content-area-bg"]
      },
      warnings: {
        50: '#F8E7E7',
        60: themeColors["item-card-bg"]
      },
      primaryShade: {
        90: themeColors["primary-90"],
        100: themeColors["primary-100"],
        200: themeColors["primary-200"],
        300: themeColors["primary-300"],
        400: themeColors["primary-400"],
        600: themeColors["primary-600"],
        700: themeColors["primary-700"],
        800: themeColors["primary-800"],
        900: themeColors["primary-900"]
      },
      primaryNeutral: {
        70: themeColors["primary-neutral"],
        80: themeColors["primary-neutral-80"],
        90: themeColors["primary-neutral-90"],
        100: themeColors["primary-neutral-100"],
        200: themeColors["primary-neutral-200"],
        300: themeColors["primary-neutral-300"],
        400: themeColors["primary-neutral-400"]
      },
      secondaryShade: {
        90: themeColors["secondary-90"],
        100: themeColors["secondary-100"],
        200: themeColors["secondary-200"],
        300: themeColors["secondary-300"],
        400: themeColors["secondary-400"],
        600: themeColors["secondary-600"],
        700: themeColors["secondary-700"],
        800: themeColors["secondary-800"],
        900: themeColors["secondary-900"]
      },
      secondaryNeutral: {
        80: themeColors["secondary-neutral"],
        90: themeColors["secondary-neutral-90"],
        100: themeColors["secondary-neutral-100"],
        200: themeColors["secondary-neutral-200"],
        300: themeColors["secondary-neutral-300"],
        400: themeColors["secondary-neutral-400"],
      },
      dark: {
        100: themeColors["primary-neutral"],
        200: '#1e1e1e',
        300: '#3e3e3e'
      },
      light: {
        100: themeColors['primary-90'],
        200: '#FFFFFF'
      }
    },
    fontFamily: ["Inter", "Mohave"].join(",")
  }),

  components: {
    MuiAutocomplete: {
      styleOverrides: {
        focused: {
          background: themeColors["primary"]
        },
        paper: {
          background: themeColors["content-area-90"]
        },

      }
    },
    BackstageWarningPanel: {
      styleOverrides: {
        summary: {
          backgroundColor: themeColors["secondary-neutral-200"],
          color: themeColors["primary-100"]
        },
        panel: {
          backgroundColor: themeColors["secondary-neutral-200"],
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: '8px',
          backgroundColor: themeColors["item-card-bg"]
        }

      }
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: themeColors["secondary"]
        }
      }
    },
    MuiFormHelperText:{
      styleOverrides: {
        root:{
          background:"transparent!important"
        }
      }
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: `${themeColors["sideBar-bg"]}!important`
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          backgroundColor: `${themeColors["content-area-bg"]}!important`
        },
        paperAnchorLeft: {
          backgroundColor: `${themeColors["content-area-bg"]}!important`
        },
        paperAnchorRight: {
          backgroundColor: `${themeColors["content-area-bg"]}!important`
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        standardError: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.error.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardInfo: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.primary.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardSuccess: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.success.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardWarning: ({ theme }) => ({
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.secondary.dark,
          '& $icon': {
            color: theme.palette.grey[700],
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: themeColors['primary-neutral-90'] // card header title
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: '200',
          color: themeColors["secondary-90"]
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: themeColors["secondary"]
        },
        // "&.Mui-checked": {
        //   color: `${themeColors["secondary"]}!important`
        // }
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // backgroundColor: `${themeColors['1100']}`,

        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          alignItems: 'center',
          margin: '10px 0!important'
        },
      }
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors["item-card-bg"]
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: "0px",
          marginBottom: "0px",
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: themeColors['primary-neutral-90'],
          border: 'none!important'
        },
        textSizeSmall: {
          border: 'none!important',
          "&:hover": {
            backgroundColor: `none!important`
          }
        },
        root: {
          borderRadius: '30px',
          border: 'none',
          outline: 'none',
          position: 'relative',
          zIndex: '0',
          background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
          "& $label": {
            fontSize: '16px',
            padding: '0 10px',
            zIndex: '1',
            position: 'relative'
          },
          "&:before": {
            content: '""',
            position: 'absolute',
            borderRadius: '30px',
            top: 2,
            right: 2,
            bottom: 2,
            left: 2,
            zIndex: '-1',
            backgroundColor: `${themeColors['content-area-bg']}`,
          },

          "&.Mui-disabled": {
            "opacity": 0.5
          }
        },
        iconSizeMedium: {
          marginLeft: 0,

        },
        outlinedPrimary: {
          color: `${themeColors['primary-neutral-90']}`,
          border: 'none',
          "&:hover": {
            border: 'none',
          }
        },
        contained: {
          cursor: 'pointer',
          borderRadius: '30px',
          fontWeight: 'bolder',
          "&:before": {
            content: '""',
            position: 'absolute',
            borderRadius: '30px',
            top: 2,
            right: 2,
            bottom: 2,
            left: 2,
            backgroundColor: `${themeColors['content-area-bg']}`,
          },
        },
      }
    },
    // PrivateTabIndicator: {
    //   styleOverrides: {
    //     root: {
    //       display: 'none'
    //     }
    //   }
    // },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
          position: 'relative',
          zIndex: 0,
          "&:before": {
            content: '""',
            position: 'absolute',
            borderRadius: '4px',
            top: 1,
            zIndex: -1,
            right: 1,
            bottom: 1,
            left: 1,
            backgroundColor: `${themeColors['content-area-bg']}`,
          },
        },
        notchedOutline: {
          'borderWidth': '0px!important'
        }
      }
    },
    BackstageHeader: {
      styleOverrides: {
        header: {
          backgroundColor: themeColors["sideBar-bg"],
          padding: '12px 24px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right center',
          backgroundSize: '70% 100%',
          backgroundImage: `url(${banner_bg_image})`,
        },
        title: {
          color: themeColors['primary-neutral-90'],

          "& $button": {
            position: 'relative',
            zIndex: 1,
            marginLeft: '8px',
            padding: '6px',
            borderRadius: '50%',
            background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
            "&:hover": {
              background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
            },
            "&:before": {
              content: '""',
              position: 'absolute',
              borderRadius: '50%',
              top: 2,
              right: 2,
              zIndex: -1,
              bottom: 2,
              left: 2,
              background: `linear-gradient(to right, ${themeColors['content-area-bg']}, ${themeColors.secondary} 500%)`,
            },
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(9,30,69,0.54)',
        },
      },
    },
    BackstageSidebar: {
      styleOverrides: {
        drawer: {
          background: themeColors['sideBar-bg']
        }
      }
    },
    BackstagePage: {
      styleOverrides: {
        root: {
          background: themeColors['content-area-bg'],

        }
      }
    },
    CatalogReactUserListPicker: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors["item-card-bg"]
        },
        menuItem: {
          borderRadius: '10px'
        }
      }
    },
    BackstageTableToolbar: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    // Component: {
    //   styleOverrides: {
    //     horizontalScrollContainer: {
    //       padding: 16
    //     }

    //   }
    // },
    BackstageTableHeader: {
      styleOverrides: {
        header: {
          borderTop: 'none',
          borderBottom: 'none',
          backgroundColor: themeColors["content-area-90"],
        }
      }
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
        highlightable: {
          "&:hover": {
            borderRadius: '10px',
            background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`
          }
        },
        buttonItem: {
          background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
          border: 'none!important',
          "&:hover": {
            background: 'none!important',
          }
        },
        selected: {
          position: 'relative',
          borderLeft: 'none!important',
          borderRadius: '10px',
          background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
          "&:hover": {
            background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
          },
          "&:before": {
            content: '""',
            position: 'absolute',
            borderRadius: '10px',
            top: 2,
            right: 2,
            bottom: 2,
            left: 2,
            background: `linear-gradient(to right, ${themeColors['content-area-bg']}, ${themeColors.secondary} 500%)`,
          },
          "& $label": {
            position: 'relative',
            zIndex: 1
          }
        },
        open: {
          width: '200px!important',
          margin: '5px auto'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: themeColors["item-card-bg"]
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: themeColors["primary-400"],
          }
        }
      }
    },
    BackstageEmptyState: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors["item-card-bg"],
          borderRadius: '10px',
          padding: '10px',
          marginTop: '5px',
          zIndex: 0,
          position: 'relative',
          borderLeft: 'none!important',
          background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
          "&:hover": {
            background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
          },
          "&:before": {
            content: '""',
            position: 'absolute',
            borderRadius: '10px',
            top: 2,
            zIndex: -1,
            right: 2,
            bottom: 2,
            left: 2,
            background: `linear-gradient(to right, ${themeColors['content-area-bg']}, ${themeColors.secondary} 500%)`,
          },
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        menuItem: {
          borderRadius:0,
          marginRight:0,
          "&:before": {
            background: 'none!important'
          },
        },
        select: {
          marginRight:0
        }


      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          fontFamily: ["Inter", "Mohave"].join(","),
          ".gradientBorder": {
            position: 'relative',
            zIndex: 1,
            borderRadius: '10px',
            background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
            "&:hover": {
              background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
            },
            "&:before": {
              content: '""',
              position: 'absolute',
              borderRadius: '10px',
              top: 2,
              right: 2,
              zIndex: -1,
              bottom: 2,
              left: 2,
              background: `linear-gradient(to right, ${themeColors['content-area-bg']}, ${themeColors.secondary} 500%)`,
            },
          },
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          marginRight: '8px',
        },
        selected: {
          color: 'white!important',
          position: 'relative',
          borderLeft: 'none!important',
          borderRadius: '10px',
          marginRight: '8px',
          zIndex: 1,
          background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
          "&:hover": {
            background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
          },
          "&:before": {
            content: '""',
            position: 'absolute',
            borderRadius: '10px',
            top: 2,
            right: 2,
            zIndex: -1,
            bottom: 2,
            left: 2,
            background: `linear-gradient(to right, ${themeColors['content-area-bg']}, ${themeColors.secondary} 500%)`,
          },

        },



      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          marginBottom: '10px'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none!important',
          backgroundColor: themeColors["item-card-bg"],
          "&:first-child": {
            borderColor: 'transparent',
            borderLeftStyle: 'solid',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px'
          },
          " &:last-child": {
            borderColor: 'transparent',
            borderRightStyle: 'solid',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px'
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          marginBottom: '10px'
        }
      }
    },
    BackstageSelectInputBase: {
      styleOverrides: {
        input: {
          background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
          position: 'relative',
          border: 'none',
          zIndex: 0,
          "&:focus": {
            background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`,
          },
          "&:before": {
            content: '""',
            position: 'absolute',
            background: themeColors["content-area-90"],
            top: 1,
            zIndex: '-1',
            bottom: 1,
            left: 1,
            right: 1,
            borderRadius: 4,
          },
        },
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: themeColors["secondary"]
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&:foucsed": {
            color: 'white'
          }
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        vertical: {
          marginLeft: '20px',
        }
      }
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          position: 'relative',
          paddingLeft: '26px',
          marginLeft: '20px',
          "&:before": {
            content: '""',
            position: 'absolute',
            width: "1px",
            background: `linear-gradient(to bottom, ${themeColors.primary}, ${themeColors.secondary})`,
            top: 1,
            bottom: 1,
            left: 1,
            borderRadius: 4
          }

        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '40px',
          height: '40px',
        },
        active: {
          color: 'transparent!important',
          borderRadius: '50%',
          background: `linear-gradient(to right, ${themeColors.primary}, ${themeColors.secondary})`
        },
        text: {
          fill: themeColors['primary-neutral-90']
        },
        completed: {
          fill: themeColors.secondary
        }
      }
    },
    BackstageLogViewer: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
        header: {
          paddingBottom: '10px'
        },
        log: {
          marginTop: '10px',

        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0px'
        }
      }
    }
  },
});
